* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  justify-content: center;
  font-size: 3rem;
}

.services {
  font-size: 50px !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 100px;
}

.products {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 100px;
}


.contact-form {
  max-width: 400px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.form-title {
  text-align: center;
  font-size: 40px;
}

.contact-info {
  text-align: center;
  margin-top: 20px;
}