.hero-container-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;

}

.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;


}

.hero-container>h1 {
  font-size: 40px;
  margin-top: -100px;
  color: #fff;
  /* color: #d61313; */
  /* font: 800 45px Arial; */
  /* font: 1000 45px sans-serif; */
}
.hero-container>h1>span{
  font: 1000 45px sans-serif;
}

.heading-span1 {
  font-size: 50px;
  color: rgb(19, 26, 121);
  
  /* background-color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.651); */
  /* font: 800 45px Arial;
  -webkit-text-fill-color: rgb(19, 26, 121);
  -webkit-text-stroke: 1px; */



}

.heading-span2 {
  font-size: 50px;
  color: rgb(228, 16, 16);
  /* font: 800 45px Arial;
  -webkit-text-fill-color: rgb(228, 16, 16);
  -webkit-text-stroke: 1px; */

}

.heading-span3 {
  font-size: 50px;
  color: rgb(29, 29, 32);
  /* font: 800 45px Arial;
  -webkit-text-fill-color: rgb(29, 29, 32);
  -webkit-text-stroke: 1px; */
  

}

.hero-container>p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: 28px;
}

.hero-btns .btn {
  margin: 5px;
}


.fa-play-circle {
  padding-left: 5px;
}

@media screen and (max-width: 960px) {
  .hero-container>h1 {
    font-size: 45px;
    margin-top: -100px;

  }
  .hero-container>h1>span {
    font-size: 50px;
    margin-top: -100px;

  }

}

@media screen and (max-width: 768px) {
  .hero-container>h1 {
    font-size: 40px;
    margin-top: -100px;

  }
  .hero-container>h1>span {
    font-size: 45px;
    margin-top: -100px;

  }

  .hero-container>p {
    font-size: 28px;

  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

}