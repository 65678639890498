.description {
    padding: 4rem;
    background-color: #272e6e;
    color: white;

}

.description-section {

    color: white;
    display: flex;
    opacity: 0.75;
    font-size: 1.1rem;
    /* padding-left: 200px;
    padding-right: 200px; */


}

/* .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
} */

.section {
    flex: 1;
    min-width: 0;
    padding: 20px;
}



.description-title {
    padding-bottom: 50px;

}

.our-services {
    background-color: #b64926;
    padding: 4rem;
    color: white;
    text-align: center;
}

.our-services-section {
    color: white;
    display: flex;
    font-size: 1.3rem;
    font-weight: bolder;
    text-align: center;
}




/* .goals-title {
    margin-top: 20px;
    padding-top: 50px;
} */

.services-title {
    padding-top: 50px;
    padding-bottom: 50px;
}

.img-container {
    background-color: rgb(246, 250, 253);
    padding-bottom: 70px;

}


.image-gallery {
    display: flex;
    justify-content: space-around;
}

.image-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    background-color: white;
    
}

.image-container img {
    width: 100%;
    height: auto;
    
    transition: transform 0.3s ease;
}

.image-container:hover img {
    transform: scale(1.1);
}

.image-text {
    text-align: center;
    margin-top: 30px;
}

.image-text h3 {
    font-size: 22px;
    margin-bottom: 5px;
}

.image-text p {
    font-size: 17px;
    padding-bottom: 50px;
}

@media (max-width: 767px) {
    .image-gallery {
        flex-wrap: wrap;
        justify-content: center;
    }

    .image-container {
        width: 90%;
        margin-bottom: 20px;
    }

    .image-text h3 {
        font-size: 18px;
    }

    .image-text p {
        font-size: 14px;
    }
}