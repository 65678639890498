:root{
    --primary: #fff;
    


}

.btn{
    padding: 8px 2px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
}

.btn--primary{
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);

}

.btn--outline{
    background-color: transparent;
    color: #ffff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transform: all 0.3 ease-out;
}

.btn--medium{
    padding: 8px 20px;
    font-size: 20px;

}

.btn--large{
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover{
    background: #ffff;
    color: #242424;
    transform: all 0.3 ease-out;

}

